// @file store for plans (pricing, quotas) and user actions to update them
import { trackEvent } from '@@/bits/analytics'
import { normalizedPaymentSchedule, SwitchType } from '@@/bits/billing_helper'
import { ALERT_ICON, THINKING_FACE_ICON } from '@@/bits/confirmation_dialog'
import { COLD_FACE_EMOJI, FACE_WITH_ROLLING_EYES_EMOJI } from '@@/bits/emoji'
import { captureMessage } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { translatePrice, __ } from '@@/bits/intl'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { currentUrl, navigateTo, reload, transformCurrentUrl, transformUrl } from '@@/bits/location'
import { poll } from '@@/bits/polling'
import { HttpCode } from '@@/enums'
import { Ab as AbApi, Billing as BillingApi, Checkout as CheckoutApi, Plan as PlanApi } from '@@/payment/padlet_api'
import { useGlobalAlertDialogStore } from '@@/pinia/global_alert_dialog'
import { ConfirmationDialogLayoutVariant, useGlobalConfirmationDialogStore } from '@@/pinia/global_confirmation_dialog'
import { useWindowSizeStore } from '@@/pinia/window_size'
import type {
  AdvertisedQuotasResult,
  EstimateCancellationRefundResult,
  EstimateSwitchingCostResult,
  Plan,
  User,
} from '@@/types'
import type { JsonAPIResource, JsonAPIResponse } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export enum UpgradeStep {
  BelowPadletQuota = 'BelowPadletQuota',
  AtPadletQuota = 'AtPadletQuota',
  OverPadletQuota = 'OverPadletQuota',
  OverFileSizeQuota = 'OverFileSizeQuota',
  SchedulePro = 'SchedulePro',
  ConfirmProMonthly = 'ConfirmProMonthly',
  ConfirmProAnnual = 'ConfirmProAnnual',
  // New values for Gold plan feature
  ChooseTier = 'ChooseTier',
  ChooseTierFrameQuota = 'ChooseTierFrameQuota',
  ChooseTierPadletQuota = 'ChooseTierPadletQuota',
  ChooseTierFileSizeQuota = 'ChooseTierFileSizeQuota',
  ChooseTierVideoRecorderQuota = 'ChooseTierVideoRecorderQuota',
  ScheduleGold = 'ScheduleGold',
  SchedulePlatinum = 'SchedulePlatinum',
  EstimatePlatinumCost = 'EstimatePlatinumCost',
  ConfirmMonthlyPlatinum = 'ConfirmMonthlyPlatinum',
  ConfirmMonthlyPlatinumDifferentCurrency = 'ConfirmMonthlyPlatinumDifferentCurrency',
  ConfirmAnnualPlatinum = 'ConfirmAnnualPlatinum',
  ConfirmAnnualPlatinumDifferentCurrency = 'ConfirmAnnualPlatinumDifferentCurrency',
  // Switch from Legacy Gold to Gold
  EstimateUpgradeGoldPlanCost = 'EstimateUpgradeGoldPlanCost',
  ConfirmUpgradeGoldPlanMonthly = 'ConfirmUpgradeGoldPlanMonthly',
  ConfirmUpgradeGoldPlanDifferentCurrencyMonthly = 'ConfirmUpgradeGoldPlanDifferentCurrencyMonthly',
  ConfirmUpgradeGoldPlanAnnual = 'ConfirmUpgradeGoldPlanAnnual',
  ConfirmUpgradeGoldPlanDifferentCurrencyAnnual = 'ConfirmUpgradeGoldPlanDifferentCurrencyAnnual',
  // Switch from Legacy Platinum to Platinum
  EstimateUpgradePlatinumPlanCost = 'EstimateUpgradePlatinumPlanCost',
  ConfirmUpgradePlatinumPlanMonthly = 'ConfirmUpgradePlatinumPlanMonthly',
  ConfirmUpgradePlatinumPlanDifferentCurrencyMonthly = 'ConfirmUpgradePlatinumPlanDifferentCurrencyMonthly',
  ConfirmUpgradePlatinumPlanAnnual = 'ConfirmUpgradePlatinumPlanAnnual',
  ConfirmUpgradePlatinumPlanDifferentCurrencyAnnual = 'ConfirmUpgradePlatinumPlanDifferentCurrencyAnnual',
}

export interface PlansState {
  plans: {
    platinum: { annual: Partial<Plan>; monthly: Partial<Plan> }
    gold: { annual: Partial<Plan>; monthly: Partial<Plan> }
    silver: { annual: Partial<Plan>; monthly: Partial<Plan> }
  }
  advertisedQuotas: AdvertisedQuotasResult
}

export enum Tier {
  Silver = 'Silver',
  Gold = 'Gold',
  Platinum = 'Platinum',
}

export enum PaymentSchedule {
  Monthly = 'Monthly',
  Annual = 'Annual',
}

export enum UpgradeSource {
  WallQuota = 'WallQuota',
  UntrashWallQuota = 'UntrashWallQuota',
  UnarchiveWallQuota = 'UnarchiveWallQuota',
  FileSizeLimit = 'FileSizeLimit',
  DashboardSettingsClick = 'DashboardSettingsClick',
  ProfileClick = 'ProfileClick',
  SurfaceProfileClick = 'SurfaceProfileClick',
  DashboardProfileClick = 'DashboardProfileClick',
  NewSignup = 'NewSignup',
  DashboardQuotaCta = 'DashboardQuotaCta',
  WhiteboardAddFrameClick = 'WhiteboardAddFrameClick',
  VideoRecorderSettingsClick = 'VideoRecorderSettingsClick',
}

const blankQuota = {
  max_upload_megabytes: null,
  max_walls: null,
  max_whiteboard_frames: null,
  max_video_length_seconds: null,
}
const blankPlan = { annual: {}, monthly: {} }
export const blankPlansState = (): PlansState => ({
  plans: {
    platinum: blankPlan,
    gold: blankPlan,
    silver: blankPlan,
  },
  advertisedQuotas: {
    neon: blankQuota,
    silver: blankQuota,
    gold: blankQuota,
    platinum: blankQuota,
  },
})

function handleExperimentConversions({ step }: { step: UpgradeStep }): void {
  if (step === UpgradeStep.ChooseTier) {
    if (isAppUsing('upgradeModalCopyAExperiment'))
      void AbApi.convertAbExperimentGoal('upgrade_modal_copy_a', 'prompted_to_upgrade')
    if (isAppUsing('upgradeModalSingleStepExperiment'))
      void AbApi.convertAbExperimentGoal('upgrade_modal_single_step', 'prompted_to_upgrade')
  } else if (step === UpgradeStep.ChooseTierPadletQuota) {
    if (isAppUsing('upgradeModalCopyAExperiment'))
      void AbApi.convertAbExperimentGoal('upgrade_modal_copy_a', 'prompted_to_upgrade')
    if (isAppUsing('upgradeModalSingleStepExperiment'))
      void AbApi.convertAbExperimentGoal('upgrade_modal_single_step', 'prompted_to_upgrade')
    void AbApi.convertAbExperimentGoal('neon_quota', 'prompted_to_upgrade')
    void AbApi.convertPersonalPricingPlansExperiment()
  } else if (step === UpgradeStep.ChooseTierFileSizeQuota) {
    if (isAppUsing('upgradeModalCopyAExperiment'))
      void AbApi.convertAbExperimentGoal('upgrade_modal_copy_a', 'prompted_to_upgrade')
    if (isAppUsing('upgradeModalSingleStepExperiment'))
      void AbApi.convertAbExperimentGoal('upgrade_modal_single_step', 'prompted_to_upgrade')
    void AbApi.convertPersonalPricingPlansExperiment()
  }
}

function trackUpgradeStep({ step, upgradeSource }: { step: UpgradeStep; upgradeSource?: UpgradeSource | null }): void {
  if (step === UpgradeStep.ChooseTier) {
    if (upgradeSource === UpgradeSource.DashboardQuotaCta) {
      trackEvent('Billing', 'Viewed membership tiers', null, null, {
        source: 'Dashboard quota cta',
      })
    } else {
      trackEvent('Billing', 'Viewed membership tiers', null, null, {
        source: 'Self upgrade',
      })
    }
  }
  if (step === UpgradeStep.ChooseTierPadletQuota) {
    trackEvent('Billing', 'Padlet quota exceeded', null, null, null)
    trackEvent('Billing', 'Viewed membership tiers', null, null, {
      source: 'Padlet quota exceeded',
    })
  }
  if (step === UpgradeStep.ChooseTierFileSizeQuota) {
    trackEvent('Billing', 'Filesize quota exceeded', null, null, null)
    trackEvent('Billing', 'Viewed membership tiers', null, null, {
      source: 'File size quota exceeded',
    })
  }
  // Log whether users are choosing Gold or Platinum plans
  if (step === UpgradeStep.ScheduleGold) {
    trackEvent('Billing', 'Chose Gold plan', null, null, null)
  }
  if (step === UpgradeStep.SchedulePlatinum) {
    trackEvent('Billing', 'Chose Platinum plan', null, null, null)
  }
}

export const usePersonalPlansStore = defineStore('personalPlans', () => {
  const globalConfirmationDialogStore = useGlobalConfirmationDialogStore()
  const globalAlertDialogStore = useGlobalAlertDialogStore()
  const windowSizeStore = useWindowSizeStore()

  // Plans state
  const plans = ref<PlansState['plans']>(blankPlansState().plans)
  const plansFetched = computed<boolean>(() => Object.keys(plans.value?.platinum.annual).length !== 0)
  async function fetchPlans(): Promise<void> {
    const response = await PlanApi.fetch()
    const fetchedPlans = response.data as unknown as Plan[]
    // Convert a list to a hash that is easy to use in our component
    const hashedPlans = {
      platinum: { annual: {}, monthly: {} },
      gold: { annual: {}, monthly: {} },
      silver: { annual: {}, monthly: {} },
    }
    for (const plan of fetchedPlans) {
      plan.formattedPrice = translatePrice(parseFloat(plan.price), plan.currency)
      hashedPlans[plan.tier][plan.period] = plan
    }
    plans.value = hashedPlans
  }

  // Quotas state
  const advertisedQuotas = ref<AdvertisedQuotasResult>(blankPlansState().advertisedQuotas)

  const isLoadingAdvertisedQuotas = computed(() => advertisedQuotas.value?.platinum.max_walls == null)

  async function fetchAdvertisedQuotas(): Promise<void> {
    const response = await PlanApi.fetchAdvertisedQuotas()
    advertisedQuotas.value = response.data
  }

  async function fetchRefunds(): Promise<void> {
    isLoadingCancellationRefundInformation.value = true
    try {
      const response: JsonAPIResponse<EstimateCancellationRefundResult> = await BillingApi.estimateCancellationRefund()
      const estimatedRefund = (response?.data as JsonAPIResource<EstimateCancellationRefundResult>)?.attributes
      estimatedCancellationRefund.value = estimatedRefund
    } catch (e) {
      if (e.status === HttpCode.UnprocessableEntity) {
        upgradeError.value = __(
          'Sorry, we are not able to process your request because your last invoice is unpaid. Please update your payment method and try again.',
        )
      } else {
        upgradeError.value = __("We're sorry, we were unable to process your request. Please try again later.")
      }
    } finally {
      isLoadingCancellationRefundInformation.value = false
    }
  }

  // Plan upgrades modal state
  const xUpgradeModal = ref(false)
  const upgradeStep = ref<UpgradeStep | null>(null)
  const upgradeError = ref('')
  const upgradeSource = ref<UpgradeSource | null>(null)
  const upgradeStatusCheckUrl = ref('')
  const isProcessingUpgrade = ref(false)
  const estimatedUpgradeCost = ref<EstimateSwitchingCostResult | null>(null)
  const hasScheduledChanges = ref(false)
  const isLoadingScheduledChangesInformation = ref(false)
  const hasUserTriggeredUpgrade = ref(false)
  const isLoadingCancellationRefundInformation = ref(false)
  const estimatedCancellationRefund = ref<EstimateCancellationRefundResult | null>(null)

  const estimatedCancellationRefundAmount = computed(() => {
    if (estimatedCancellationRefund.value == null) return null
    return translatePrice(
      parseFloat(estimatedCancellationRefund.value.amount),
      estimatedCancellationRefund.value.currency,
    )
  })

  // Plan details, normalized for display
  const displayedEstimatedUpgradeCost = computed<EstimateSwitchingCostResult>(() => {
    if (estimatedUpgradeCost.value == null) {
      return {
        is_refund: false,
        currency_code: '',
        new_plan_cost: '',
        unused_portion: '',
        refund_amount: '',
        amount_due: '',
        invoice_amount: '',
      }
    }
    return estimatedUpgradeCost.value
  })

  const displayedQuotas = computed(() => {
    function formatNullableNumber(value: number | null): string {
      if (value == null) return ''
      return String(value)
    }

    return {
      platinum: {
        max_walls: advertisedQuotas.value.platinum.max_walls ?? 0,
        max_upload_megabytes: formatNullableNumber(advertisedQuotas.value.platinum.max_upload_megabytes),
        max_whiteboard_frames: advertisedQuotas.value.platinum.max_whiteboard_frames ?? 0,
        max_video_length_seconds: advertisedQuotas.value.platinum.max_video_length_seconds ?? 0,
      },
      gold: {
        max_walls: advertisedQuotas.value.gold.max_walls ?? 0,
        max_upload_megabytes: formatNullableNumber(advertisedQuotas.value.gold.max_upload_megabytes),
        max_whiteboard_frames: advertisedQuotas.value.gold.max_whiteboard_frames ?? 0,
        max_video_length_seconds: advertisedQuotas.value.gold.max_video_length_seconds ?? 0,
      },
      silver: {
        max_walls: advertisedQuotas.value.silver.max_walls ?? 0,
        max_upload_megabytes: formatNullableNumber(advertisedQuotas.value.silver.max_upload_megabytes),
        max_whiteboard_frames: advertisedQuotas.value.silver.max_whiteboard_frames ?? 0,
        max_video_length_seconds: advertisedQuotas.value.silver.max_video_length_seconds ?? 0,
      },
      neon: {
        max_walls: advertisedQuotas.value.neon.max_walls ?? 0,
        max_upload_megabytes: formatNullableNumber(advertisedQuotas.value.neon.max_upload_megabytes),
        max_whiteboard_frames: advertisedQuotas.value.neon.max_whiteboard_frames ?? 0,
        max_video_length_seconds: advertisedQuotas.value.neon.max_video_length_seconds ?? 0,
      },
    }
  })

  const displayedPrices = computed(() => {
    return {
      platinum: {
        monthly: plans.value.platinum.monthly.formattedPrice ?? '',
        annual: plans.value.platinum.annual.formattedPrice ?? '',
      },
      gold: {
        monthly: plans.value.gold.monthly.formattedPrice ?? '',
        annual: plans.value.gold.annual.formattedPrice ?? '',
      },
      silver: {
        annual: plans.value.silver.annual.formattedPrice ?? '',
      },
    }
  })

  async function fetchEstimatedUpgradeCost({ paymentSchedule }): Promise<void> {
    const body = asciiSafeStringify({
      plan_id: plans.value.platinum[normalizedPaymentSchedule(paymentSchedule)].id,
    })

    try {
      estimatedUpgradeCost.value = await BillingApi.estimateSwitchingCost({ body })
    } catch (e) {
      switch (e.status) {
        case HttpCode.UnprocessableEntity:
          upgradeError.value = __(
            'Sorry, we are not able to process your request because your last invoice is unpaid. Please update your payment method and try again.',
          )
          break
        case 406:
          closePlanUpgradeModal()
          triggerSwitchingErrorBillingSettingsRedirect()
          break
        default:
          upgradeError.value = __("We're sorry, we were unable to process your request. Please try again later.")
      }
    }
  }

  function showAboveQuotaOnUpgradeConfirmationDialog({ wallsUsed }: { wallsUsed: number }): void {
    globalConfirmationDialogStore.openConfirmationDialog({
      ...THINKING_FACE_ICON,
      layoutVariant: windowSizeStore.isSmallerThanTabletPortrait
        ? ConfirmationDialogLayoutVariant.Drawer
        : ConfirmationDialogLayoutVariant.Large,
      title: __('You’re above this tier’s padlet limit'),
      body: __(
        'You currently have %{amount} padlets, but our Gold tier only supports %{goldQuota}. You won’t be able to create new padlets until you are below the Gold tier limit. Consider switching to our Platinum tier instead.',
        { amount: wallsUsed, goldQuota: advertisedQuotas.value.gold.max_walls },
      ),
      confirmButtonText: __('Continue with Gold'),
      cancelButtonText: __('Nevermind'),
      afterConfirmActions: [() => showPlanUpgradeToGoldModal({ upgradeSource: UpgradeSource.DashboardSettingsClick })],
    })
  }

  function triggerScheduledChangesConfirmationDialog(): void {
    globalConfirmationDialogStore.openConfirmationDialog({
      ...COLD_FACE_EMOJI,
      layoutVariant: windowSizeStore.isSmallerThanTabletPortrait
        ? ConfirmationDialogLayoutVariant.Drawer
        : ConfirmationDialogLayoutVariant.Large,
      title: __('Upgrade currently unavailable'),
      body: __(
        'We are unable to upgrade your membership due to changes that are pending in your account. You can check your membership status and make changes from the billing page in settings.',
      ),
      confirmButtonText: __('Go to billing'),
      cancelButtonText: __('Cancel'),
      afterConfirmActions: [navigateToBillingSettingsPage],
      shouldFadeIn: false,
    })
  }

  function triggerSwitchingErrorBillingSettingsRedirect(): void {
    globalConfirmationDialogStore.openConfirmationDialog({
      ...FACE_WITH_ROLLING_EYES_EMOJI,
      layoutVariant: windowSizeStore.isSmallerThanTabletPortrait
        ? ConfirmationDialogLayoutVariant.Drawer
        : ConfirmationDialogLayoutVariant.Large,
      title: __('Upgrade currently unavailable'),
      body: __('We are unable to process this action from this page. Please visit the billing page and try again'),
      confirmButtonText: __('Go to billing'),
      cancelButtonText: __('Cancel'),
      afterConfirmActions: [navigateToBillingSettingsPage],
      shouldFadeIn: false,
    })
  }

  function navigateToBillingSettingsPage(): void {
    navigateTo('/dashboard/settings/billing')
  }

  function resetUpgradeModalState(): void {
    xUpgradeModal.value = false
    upgradeStep.value = null
    upgradeError.value = ''
    upgradeSource.value = null
    upgradeStatusCheckUrl.value = ''
    isProcessingUpgrade.value = false
    estimatedUpgradeCost.value = null
    hasScheduledChanges.value = false
    isLoadingScheduledChangesInformation.value = false
    hasUserTriggeredUpgrade.value = false
    isLoadingCancellationRefundInformation.value = false
    estimatedCancellationRefund.value = null
  }

  function closePlanUpgradeModal(): void {
    resetUpgradeModalState()
  }

  function setPlanUpgradeSource(payload: UpgradeSource): void {
    upgradeSource.value = payload
  }

  function showPlanUpgradeModal({
    step,
    upgradeSource,
  }: {
    upgradeSource?: UpgradeSource | null
    step: UpgradeStep
  }): void {
    if (upgradeSource != null) {
      setPlanUpgradeSource(upgradeSource)
    }
    xUpgradeModal.value = true
    upgradeStep.value = step
    // Log events to track how users are getting to the upgrade modal
    trackUpgradeStep({ step, upgradeSource })
    // Perform experiment conversions
    handleExperimentConversions({ step })
  }

  async function showUpgradeGoldPlanModal({ paymentSchedule, userPlanCurrency }): Promise<void> {
    if (plans.value.gold.monthly.currency === userPlanCurrency) {
      showPlanUpgradeModal({
        step: UpgradeStep.EstimateUpgradeGoldPlanCost,
        upgradeSource: UpgradeSource.DashboardSettingsClick,
      })
      await fetchEstimatedUpgradeCost({ paymentSchedule })
      upgradeStep.value =
        paymentSchedule === 'month'
          ? UpgradeStep.ConfirmUpgradeGoldPlanMonthly
          : UpgradeStep.ConfirmUpgradeGoldPlanAnnual
    } else {
      void fetchRefunds()

      showPlanUpgradeModal({
        step:
          paymentSchedule === 'month'
            ? UpgradeStep.ConfirmUpgradeGoldPlanDifferentCurrencyMonthly
            : UpgradeStep.ConfirmUpgradeGoldPlanDifferentCurrencyAnnual,
        upgradeSource: UpgradeSource.DashboardSettingsClick,
      })
    }
  }

  function showPlanUpgradeToGoldModal({ upgradeSource = null }: { upgradeSource: UpgradeSource | null }): void {
    showPlanUpgradeModal({ step: UpgradeStep.ScheduleGold, upgradeSource })
  }

  function showPlanUpgradeToGoldModalCheckingQuota({
    tierQuota,
    wallsUsed,
  }: {
    tierQuota: number
    wallsUsed: number
  }): void {
    if (wallsUsed > tierQuota) {
      showAboveQuotaOnUpgradeConfirmationDialog({ wallsUsed })
      return
    }
    showPlanUpgradeToGoldModal({ upgradeSource: UpgradeSource.DashboardSettingsClick })
  }

  async function upgradeToTier({ paymentSchedule, tier }): Promise<void> {
    try {
      isProcessingUpgrade.value = true
      const body = asciiSafeStringify({
        switch_type: SwitchType.SwitchImmediately,
        plan_id: plans.value[tier][normalizedPaymentSchedule(paymentSchedule)].id,
      })
      const {
        links: { status },
      } = await BillingApi.switchPlan({ body })
      const response = await poll({
        pollingUrl: status,
        validationCallback: (response) => Object.prototype.hasOwnProperty.call(response, 'switch_status'),
        options: { intervalSecs: 5, maxAttempts: 10 },
      })
      if (Object.prototype.hasOwnProperty.call(response.switch_status, 'error_message')) {
        upgradeError.value = response.switch_status.error_message
      } else if (response.switch_status?.is_switched === false) {
        upgradeError.value = __("We're sorry, we were unable to process your request. Please try again later.")
      } else if (
        Object.prototype.hasOwnProperty.call(response.switch_status, 'is_refunded') &&
        response.switch_status.is_refunded === false
      ) {
        closePlanUpgradeModal()
        globalAlertDialogStore.openAlertDialog({
          ...ALERT_ICON,
          title: __('Refund failed!'),
          body: __(
            'You have successfully switched plans! Unfortunately, your bank is unable to process your refund. We have extended your term end for now.',
          ),
          closeButtonText: __('Okay'),
          afterCloseActions: [reload],
        })
      } else {
        // Redirect the user to /memberships/confirmation and add the "next" query param
        // containing the current URL.
        navigateTo(transformCurrentUrl({}, { path: '/memberships/confirmation', search: { next: currentUrl() } }))
      }
    } catch (e) {
      upgradeError.value = __("We're sorry, we were unable to process your request. Please try again later.")
    } finally {
      isProcessingUpgrade.value = false
    }
  }

  function upgradeToPlatinum({ paymentSchedule }): void {
    void upgradeToTier({ paymentSchedule, tier: 'platinum' })
  }

  async function showUpgradePlatinumPlanModal({ paymentSchedule, userPlanCurrency }): Promise<void> {
    if (plans.value.platinum.monthly.currency === userPlanCurrency) {
      showPlanUpgradeModal({
        step: UpgradeStep.EstimateUpgradePlatinumPlanCost,
        upgradeSource: UpgradeSource.DashboardSettingsClick,
      })
      await fetchEstimatedUpgradeCost({ paymentSchedule })
      upgradeStep.value =
        paymentSchedule === 'month'
          ? UpgradeStep.ConfirmUpgradePlatinumPlanMonthly
          : UpgradeStep.ConfirmUpgradePlatinumPlanAnnual
    } else {
      void fetchRefunds()
      showPlanUpgradeModal({
        step:
          paymentSchedule === 'month'
            ? UpgradeStep.ConfirmUpgradePlatinumPlanDifferentCurrencyMonthly
            : UpgradeStep.ConfirmUpgradePlatinumPlanDifferentCurrencyAnnual,
        upgradeSource: UpgradeSource.DashboardSettingsClick,
      })
    }
  }

  function showUpgradeToPlatinumModal({ membershipTier, paymentSchedule, userPlanCurrency }): void {
    if (membershipTier === 'neon') {
      showPlanUpgradeModal({
        step: UpgradeStep.SchedulePlatinum,
        upgradeSource: UpgradeSource.DashboardSettingsClick,
      })
    } else if (membershipTier === 'gold' || membershipTier === 'silver') {
      if (plans.value.platinum.monthly.currency === userPlanCurrency) {
        void showUpgradeFromGoldToPlatinumModal({
          paymentSchedule,
          upgradeSource: UpgradeSource.DashboardSettingsClick,
        })
      } else {
        void showUpgradeFromGoldToPlatinumDifferentCurrencyModal({
          paymentSchedule,
          upgradeSource: UpgradeSource.DashboardSettingsClick,
        })
      }
    } else {
      captureMessage('User tried to upgrade to Platinum while on Platinum.')
    }
  }

  function showUpgradeFromGoldToPlatinumModal({ paymentSchedule, upgradeSource }): void {
    showPlanUpgradeModal({
      step: UpgradeStep.EstimatePlatinumCost,
      upgradeSource,
    })
    void fetchEstimatedUpgradeCost({ paymentSchedule })

    upgradeStep.value =
      paymentSchedule === 'month' ? UpgradeStep.ConfirmMonthlyPlatinum : UpgradeStep.ConfirmAnnualPlatinum
  }

  function showUpgradeFromGoldToPlatinumDifferentCurrencyModal({ paymentSchedule, upgradeSource }): void {
    void fetchRefunds()
    showPlanUpgradeModal({
      step:
        paymentSchedule === 'month'
          ? UpgradeStep.ConfirmMonthlyPlatinumDifferentCurrency
          : UpgradeStep.ConfirmAnnualPlatinumDifferentCurrency,
      upgradeSource,
    })
  }

  function showUpgradeToPlatinumModalCheckingScheduledChanges({ membershipTier, paymentSchedule }): void {
    if (membershipTier === 'neon') {
      showPlanUpgradeModal({ step: UpgradeStep.SchedulePlatinum })
      return
    }
    // If scheduled changes is still loading, we wait for that to finish first before `showUpgradeFromGoldToPlatinumModal`
    if (isLoadingScheduledChangesInformation.value) {
      hasUserTriggeredUpgrade.value = true
      return
    }
    if (hasScheduledChanges.value) {
      closePlanUpgradeModal()
      triggerScheduledChangesConfirmationDialog()
    }
    void changeStepUpgradeFromGoldToPlatinumModal({ paymentSchedule })
  }

  function upgradeToGold({ paymentSchedule }): void {
    void upgradeToTier({ paymentSchedule, tier: 'gold' })
  }

  function upgradeToGoldDifferentCurrency({ paymentSchedule }): void {
    void upgradeToTierDifferentCurrency({ paymentSchedule, tier: 'gold' })
  }

  async function upgradeToTierDifferentCurrency({ paymentSchedule, tier }): Promise<void> {
    try {
      isProcessingUpgrade.value = true
      const body = asciiSafeStringify({
        plan_id: plans.value[tier][normalizedPaymentSchedule(paymentSchedule)].id,
      })
      await BillingApi.switchCurrency({ body })
      // Redirect the user to /memberships/confirmation and add the "next" query param
      // containing the current URL.
      navigateTo(transformCurrentUrl({}, { path: '/memberships/confirmation', search: { next: currentUrl() } }))
    } catch (e) {
      try {
        if (JSON.parse(e.message).error === 'refund_failed') {
          closePlanUpgradeModal()
          globalAlertDialogStore.openAlertDialog({
            ...ALERT_ICON,
            title: __('Refund failed!'),
            body: __(
              'Sorry, we were unable to refund your previous subscription through your card. We have extended your current subscription.',
            ),
            closeButtonText: __('Okay'),
            afterCloseActions: [reload],
          })
        } else {
          upgradeError.value = JSON.parse(e.message).error
        }
      } catch {
        upgradeError.value = __("We're sorry, we were unable to process your request. Please try again later.")
      }
    } finally {
      isProcessingUpgrade.value = false
    }
  }

  async function changeStepUpgradeFromGoldToPlatinumModal({ paymentSchedule }): Promise<void> {
    upgradeStep.value = UpgradeStep.EstimatePlatinumCost
    await fetchEstimatedUpgradeCost({ paymentSchedule })
    upgradeStep.value =
      paymentSchedule === 'month' ? UpgradeStep.ConfirmMonthlyPlatinum : UpgradeStep.ConfirmAnnualPlatinum
  }

  function upgradeToPlatinumDifferentCurrency({ paymentSchedule }): void {
    void upgradeToTierDifferentCurrency({ paymentSchedule, tier: 'platinum' })
  }

  function showPlanUpgradeModalCheckingScheduledChanges({
    step,
    membershipTier,
    paymentSchedule,
    upgradeSource,
  }): void {
    if (!plansFetched.value) {
      void fetchPlans()
      void fetchAdvertisedQuotas()
    }
    showPlanUpgradeModal({ step, upgradeSource })
    if (membershipTier === 'gold') {
      void fetchScheduledChanges({ paymentSchedule })
    }
  }

  async function fetchScheduledChanges({ paymentSchedule }): Promise<void> {
    isLoadingScheduledChangesInformation.value = true
    try {
      const response = await BillingApi.fetchScheduledChanges()
      hasScheduledChanges.value = response.data.has_scheduled_changes
      void handleScheduledChangesLoaded({ paymentSchedule })
    } finally {
      isLoadingScheduledChangesInformation.value = false
    }
  }

  function handleScheduledChangesLoaded({ paymentSchedule }): void {
    // If the user already triggered an upgrade from Gold to Platinum and is waiting for loading schedule changes to finish
    if (hasUserTriggeredUpgrade.value) {
      hasUserTriggeredUpgrade.value = false
      if (hasScheduledChanges.value) {
        closePlanUpgradeModal()
        triggerScheduledChangesConfirmationDialog()
      } else {
        void changeStepUpgradeFromGoldToPlatinumModal({ paymentSchedule })
      }
    }
  }

  async function generateCheckoutUrl(payload: {
    paymentSchedule: string
    tier: Tier
    postCheckoutRedirectUrl: string
  }): Promise<string> {
    if (!isAppUsing('stripeCheckout')) {
      const searchParams = new URLSearchParams({
        upgrade_source: upgradeSource.value ?? '',
        next: payload.postCheckoutRedirectUrl,
      })
      if (payload.tier === Tier.Silver && payload.paymentSchedule === PaymentSchedule.Annual) {
        trackEvent('Billing', 'Chose Silver annual')
        return transformUrl(plans.value.silver.annual.url as string, { searchParams })
      }
      if (payload.tier === Tier.Gold && payload.paymentSchedule === PaymentSchedule.Monthly) {
        trackEvent('Billing', 'Chose Gold monthly')
        return transformUrl(plans.value.gold.monthly.url as string, { searchParams })
      }
      if (payload.tier === Tier.Gold && payload.paymentSchedule === PaymentSchedule.Annual) {
        trackEvent('Billing', 'Chose Gold annual')
        return transformUrl(plans.value.gold.annual.url as string, { searchParams })
      }
      if (payload.tier === Tier.Platinum && payload.paymentSchedule === PaymentSchedule.Monthly) {
        trackEvent('Billing', 'Chose Platinum monthly')
        return transformUrl(plans.value.platinum.monthly.url as string, { searchParams })
      }
      if (payload.tier === Tier.Platinum && payload.paymentSchedule === PaymentSchedule.Annual) {
        trackEvent('Billing', 'Chose Platinum annual')
        return transformUrl(plans.value.platinum.annual.url as string, { searchParams })
      }
      return ''
    } else {
      let selectedPlan: Plan
      if (payload.tier === Tier.Silver && payload.paymentSchedule === PaymentSchedule.Annual) {
        selectedPlan = plans.value.silver.annual as Plan
      } else if (payload.tier === Tier.Gold && payload.paymentSchedule === PaymentSchedule.Monthly) {
        selectedPlan = plans.value.gold.monthly as Plan
      } else if (payload.tier === Tier.Gold && payload.paymentSchedule === PaymentSchedule.Annual) {
        selectedPlan = plans.value.gold.annual as Plan
      } else if (payload.tier === Tier.Platinum && payload.paymentSchedule === PaymentSchedule.Monthly) {
        selectedPlan = plans.value.platinum.monthly as Plan
      } else {
        selectedPlan = plans.value.platinum.annual as Plan
      }

      const response = await CheckoutApi.fetchCheckoutPageUrl({
        priceId: selectedPlan?.id,
        nextUrl: payload.postCheckoutRedirectUrl,
      })
      return response.checkoutPageUrl
    }
  }

  async function handleUpgrade(payload: {
    paymentSchedule: string
    tier: Tier
    postCheckoutRedirectUrl: string
  }): Promise<void> {
    const checkoutUrl = await generateCheckoutUrl(payload)
    navigateTo(checkoutUrl)
  }

  // #region trigger upgrade modal
  function userTriggeredUpgrade({
    user,
    upgradeSource,
    step,
  }: {
    user: User
    upgradeSource: UpgradeSource
    step?: UpgradeStep
  }): void {
    void showPlanUpgradeModalCheckingScheduledChanges({
      step: step ?? UpgradeStep.ChooseTier,
      membershipTier: user.membership_tier,
      paymentSchedule: user.period,
      upgradeSource,
    })
  }

  function quotaTriggeredUpgrade({ user, upgradeSource }: { user: User; upgradeSource?: UpgradeSource }): void {
    void showPlanUpgradeModalCheckingScheduledChanges({
      step: UpgradeStep.ChooseTierPadletQuota,
      membershipTier: user.membership_tier,
      paymentSchedule: user.period,
      upgradeSource: upgradeSource ?? UpgradeSource.WallQuota,
    })
  }

  function videoRecorderQuotaTriggeredUpgrade({
    user,
    upgradeSource,
  }: {
    user: User
    upgradeSource: UpgradeSource
  }): void {
    void showPlanUpgradeModalCheckingScheduledChanges({
      step: UpgradeStep.ChooseTierVideoRecorderQuota,
      membershipTier: user.membership_tier,
      paymentSchedule: user.period,
      upgradeSource,
    })
  }
  // #endregion

  function initialize(): void {
    void Promise.all([fetchPlans(), fetchAdvertisedQuotas()])
  }

  function getAnnualSavingsPercentageForTier(tier: 'gold' | 'platinum'): number | null {
    const annualPlan = plans.value[tier].annual
    return annualPlan.annual_savings_percentage ?? null
  }

  return {
    // getters
    advertisedQuotas,
    displayedEstimatedUpgradeCost,
    displayedPrices,
    displayedQuotas,
    estimatedCancellationRefundAmount,
    estimatedUpgradeCost,
    hasUserTriggeredUpgrade,
    isLoadingAdvertisedQuotas,
    isLoadingCancellationRefundInformation,
    isProcessingUpgrade,
    plans,
    plansFetched,
    upgradeError,
    upgradeSource,
    upgradeStep,
    xUpgradeModal,

    // actions
    closePlanUpgradeModal,
    fetchAdvertisedQuotas,
    fetchPlans,
    setPlanUpgradeSource,
    showPlanUpgradeToGoldModal,
    showPlanUpgradeToGoldModalCheckingQuota,
    showUpgradeGoldPlanModal,
    showUpgradePlatinumPlanModal,
    showUpgradeToPlatinumModal,
    showUpgradeToPlatinumModalCheckingScheduledChanges,
    showPlanUpgradeModalCheckingScheduledChanges,
    upgradeToGold,
    upgradeToGoldDifferentCurrency,
    upgradeToPlatinum,
    upgradeToPlatinumDifferentCurrency,
    generateCheckoutUrl,
    handleUpgrade,
    initialize,
    getAnnualSavingsPercentageForTier,

    // trigger upgrade modal
    userTriggeredUpgrade,
    quotaTriggeredUpgrade,
    videoRecorderQuotaTriggeredUpgrade,
  }
})
